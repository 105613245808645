import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';


function SimpleBreadcrumbs() {
  const classes = useStyles();
  //Obtenemos un array con toda la ruta
  let route = document.location.hash.replace("#/","").split("/");
  
  if (route.length === 1 && route[0] === "")
    route = [];

  let routeToGo = "";
  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.marginBottom}>
      <Link className={classes.noUnderline} to="/">
        Home
      </Link>
      {route.map((item, index) => {  

        item =item[0].toUpperCase() + item.substr(1, item.length-1);
        
        if (index === (route.length-1)){
          if (item.substr(0, 3) === "Id-")
            item = "Edit record";
          return (<Typography color="textPrimary" key={item+""+index}>{item}</Typography>);
        }else{

          routeToGo += "/"+item;

          if (item.substr(0, 3) === "Id-")
            item = "Edit record";

          return (
          <Link key={item+""+index} className={classes.noUnderline} to={routeToGo}>
            {item}
          </Link>);
        }
      })}

    </Breadcrumbs>
  );
}

const useStyles = makeStyles((theme) => ({
    noUnderline: {
      textDecoration: "none",
      color: "inherit",
    },
    marginBottom:{
      marginBottom: "25px",
    }
  }));

export default withRouter(props => <SimpleBreadcrumbs {...props}/>);