import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import {Link} from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  noUnderline: {
    textDecoration: "none",
    color: "inherit",
    cursor: "pointer",
  }
}));

export default function PermanentDrawerLeft() {
  const classes = useStyles();

  return (

    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
          paper: classes.drawerPaper,
      }}
      anchor="left"
      >
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <Link to="/" className={classes.noUnderline}>
          <ListItem button key={'Clients'}>
            <ListItemIcon><Icon>work</Icon></ListItemIcon>
            <ListItemText primary={'Clients'} />
          </ListItem>
        </Link>
        <Link to="/log" className={classes.noUnderline}>
          <ListItem button key={'Log'}>
            <ListItemIcon><Icon>list_alt</Icon></ListItemIcon>
            <ListItemText primary={'Log'} />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        <Link to="/about" className={classes.noUnderline}>
          <ListItem button key={'About'}>
            <ListItemIcon><Icon>info</Icon></ListItemIcon>
            <ListItemText primary={'About'} />
          </ListItem>
        </Link>
      </List>
    </Drawer>
  );
}