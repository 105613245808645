import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function FormDialog(props) {
  const handleCreate = ()=>{
    let licenseRequest = {
      request: document.getElementById('request').value,
      tenants: document.getElementById('tenants').value,
      channels: document.getElementById('channels').value,
      users: document.getElementById('users').value,
      date: document.getElementById('date').value,
      endUserName: props.client.name,
    }
    props.handleCreate(licenseRequest);
  };

  return (
    <div>
      <Dialog open={props.showDialog} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">New License</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Complete this form to get a signed license. This action will be registered.
          </DialogContentText>

          <TextField
            id="request"
            label="License Request"
            multiline
            fullWidth
            rows="4"/>
          <TextField
            autoFocus
            margin="dense"
            id="tenants"
            label="Tenants Number"
            type="number"
            style={{marginRight: "20px"}}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="channels"
            label="Channels Number"
            type="number"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="users"
            label="Users Number"
            type="number"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="date"
            label="Expiration Date (dd-mm-yyyy)"
            type="text"
            fullWidth
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreate} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}