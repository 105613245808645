import React from 'react';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

function About(){

    return(
        <React.Fragment>
            <Typography variant="h5" paragraph={true} gutterBottom={true}>
                About VSNLICENSER
            </Typography>
            <Typography paragraph={true} gutterBottom={true} component="p"> 
                VSNLICENSER allows VSN's employees to easily generate licenses certificates, which are required to activate and use VSN software installed in local environments. In order to provide an intuitive and fast user interface, VSNLICENSER acts as a middleware between the final software that signs and generates the certificate, and the end-user.
            </Typography>
            <Typography paragraph={true} gutterBottom={true} component="p"> 
                Last Update: April 2020
            </Typography>
            <Typography paragraph={true} gutterBottom={true} component="p"> 
                Coded with <Icon fontSize="small">favorite</Icon> from Terrassa.
            </Typography>
        </React.Fragment>
    );

}


export default About;