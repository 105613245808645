import BasicApi from './BasicApi';

/**
 * Llamadas para gestionar el login
 */
class LoginApi extends BasicApi {

    async get(token) {
        let response = await super.apiGet("session/token/"+token);
        return response;
    }

    async verifyStoredSession() {
        let response = await super.apiGet("session/check");
        return response;
    }
}

export default LoginApi;