import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from './../AppStructure/Table';
import {
  withRouter
} from 'react-router-dom'
//Apis
import ClientApi from '../../api/ClientApi';


function Logs(props){

  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);

  useEffect(() =>{(async function(){
    let clientApi = new ClientApi();
    let clients = await clientApi.getLogs();
    setClients(clients.data);
    setLoading(false);
  })()}, [loading, setClients, setLoading]);

  if (loading === true){
    return <LinearProgress variant="query" />;
  }
  
  return (
    <React.Fragment>
      <Table columns={[{label: 'Log', value: 'log'}, {label: 'Date', value: 'date'}]} rows={clients} />
    </React.Fragment>
);
}

export default withRouter(Logs);