import { createSlice } from '@reduxjs/toolkit';

//Creamos el reducer
export const slice = createSlice({
    name: 'session',
    initialState: {
      logged: false,
      username: '',
      email: '',
      department: '',
    },
    reducers: {
      logout: state => {
        state.logged = false;
      },
      login: (state, action) => {
        state.logged = true;
        state.username = action.payload.username;
        state.email = action.payload.email;
        state.department = action.payload.department;
      },
    },
});

//Exportamos las acciones
export const { logout, login } = slice.actions;

//Selectores
export const selectSession = state => state.session;

export default slice.reducer;