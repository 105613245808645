import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function FormDialog(props) {

  return (
    <div>
      <Dialog open={props.showDialog} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">License Creation Result</DialogTitle>
        <DialogContent>
        {(!props.license.includes("Unhandled Exception")) && <DialogContentText>
            Your license has been successfully generated and signed. Please copy the following certificate in the Management panel.
          </DialogContentText>}

        {(props.license.includes("Unhandled Exception")) && <DialogContentText>
            An unhandled error ocurred. Please try again later or contact with an administrator.
          </DialogContentText>}

          <TextField
            id="request"
            label="License Certificate"
            multiline
            fullWidth
            rows="6"
            value={props.license}/>

        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}