import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

//Redux
import { useSelector } from 'react-redux';
import { selectSession} from './app/slices/sessionSlice';

//Dependencias propias
import Login from './features/Login/Login';
import AppBar from './features/AppStructure/AppBar';
import Drawer from './features/AppStructure/Drawer';
import Routes from './Routes.js';
import Breadcrumbs from './features/AppStructure/Breadcrumbs';

//React router
import {
  HashRouter as Router,
  Switch,
} from "react-router-dom";


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function App() {

  //Recuperamos datos de la sesión
  const session = useSelector(selectSession);
  const appName = "VSNLICENSER";
  //const theme = useTheme();
  const classes = useStyles();

  //Si no estamos logueados, el flujo va directo al sistema de login
  if (session.logged === false){
    return(
      <Login appName={appName} />
    );
  }else{

    return (
      <Router>
        <div className={classes.root}>
          <AppBar appName={appName} className={clsx(classes.appBar, {
            [classes.appBarShift]: true,
          })} />
          <Drawer />
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: true,
            })}
          >
            <div className={classes.drawerHeader} />
            <Breadcrumbs />
            <Switch>
              <Routes />
            </Switch>
          </main>
        </div>
      </Router>
    );
  }

} 

export default App;
