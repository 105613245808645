import axios from 'axios';

class BasicApi {

    /**
     * CTOR
     */
    constructor() {
        
        //Si estamos en localhost puerto 3000 apuntamos a localhost
        const host = document.location.href.split("/")[2];
        if (host === "localhost:3000") {
            axios.defaults.baseURL = "http://localhost:3001";
        }
    }

    /**
     * Habilita la cache en las llamadas API
     */
    enableCache() {
        this.cache = true;
        this.history = [];
    }

    /**
     * Deshabilita la cache en las llamadas API 
     */
    disableCache() {
        this.cache = false;
        this.history = [];
    }

    /**
     * Añade a la memoria cache el resultado de una petición
     * @param {String} method Método http utilizado en la petición
     * @param {String} url Url de la petición
     * @param {Object} result Resultado de la petición
     * @param {Object} body Cuerpo de la petición realizada (salvo GET)
     */
    _addToHistory(method, url, result, body = {}) {
        //Si estamos utilizando cache, almacenamos el resultado
        if (this.cache === true) {
            this.history.push({ method, url, result, body });
        }
    }

    /**
     * Recupera el resultado de una llamada si está en cache
     * @param {String} method Método http utilizado en la petición
     * @param {String} url Url de la petición
     * @param {Object} result Resultado de la petición
     * @param {Object} body Cuerpo de la petición realizada (salvo GET)
     */
    _getFromHistory(method, url, body = {}) {
        if (this.cache === true) {
            //Iteramos el histórico
            try {
                for (let i in this.history) {
                    let call = this.history[i];
                    if (call.method === method && call.url === url && JSON.stringify(call.body) === JSON.stringify(body)) {
                        return call.result;
                    }
                }

                return null;

            } catch(exception){
                console.error(exception);
                return null;
            }

        } else {
            return null;
        }
    }

    //#endregion

    /**
     * Descarga un fichero de una url
     * @param {String} url Url relativa de la que descargar el fichero
     * @param {String} fileName Nombre con el que descargar el fichero
     */
    async apiGetFile(url, fileName) {
        try {
            let config = this._getRequestConfig();
            config.responseType = 'blob';

            //Lanzamos petición get
            let response = await axios.get('/api/' + url, config);

            const resultUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = resultUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

        } catch (exception) {
            console.error("Error al realizar petición GET al endpoint " + url);
            console.error(exception);
            return null;
        }
    }

    /**
     * Realiza una petición GET a la API
     * @param {String} url Url a llamar
     * @returns {Object} Resultado de la petición
     */
    async apiGet(url, exceptionCallback) {

        try {
            //Construímos URL
            let finalUrl = '/api/' + url;
            //Comprobamos si está en cache
            let fromHistory = this._getFromHistory('GET', finalUrl);
            if (fromHistory !== null)
                return fromHistory;

            //Lanzamos petición get
            let response = await axios.get(finalUrl, this._getRequestConfig());
            if (response.data.success === true) {
                //Si estamos utilizando cache, almacenamos el resultado
                this._addToHistory('GET', finalUrl, response.data);

                //Éxito
                return response.data;
            } else {
                //Error
                console.error("Error al realizar petición GET al endpoint " + url);
                return null;
            }
        } catch (exception) {
            console.error("Error al realizar petición GET al endpoint " + url);
            console.error(exception);
            if (typeof exceptionCallback === 'function')
                exceptionCallback(exception);
            return null;
        }
    }

    /**
     * Realiza una petición PUT a la API
     * @param {String} url Url a llamar
     * @param {Object} data Cuerpo de la petición
     * @returns {Object} Resultado de la petición
     */
    async apiPut(url, data) {
        try {
            //Construímos URL
            let finalUrl = '/api/' + url;

            //Comprobamos si está en cache
            let fromHistory = this._getFromHistory('PUT', finalUrl, data);
            if (fromHistory !== null)
                return fromHistory;

            //Lanzamos petición put
            let response = await axios.put(finalUrl, data, this._getRequestConfig());
            if (response.data.success === true) {
                //Si estamos utilizando cache, almacenamos el resultado
                this._addToHistory('PUT', finalUrl, response.data, data);

                //Éxito
                return response.data;
            } else {
                //Error
                console.error("Error al realizar petición PUT al endpoint " + url);
                return null;
            }
        } catch (exception) {
            console.error("Error al realizar petición PUT al endpoint " + url);
            return null;
        }
    }

    /**
     * Descarga un fichero de una url
     * @param {String} url Url relativa de la que descargar el fichero
     * @param {Object} data Cuerpo de la petición
     * @param {String} fileName Nombre con el que descargar el fichero
     */
    async apiPostFile(url, data, fileName) {
        try {
            let config = this._getRequestConfig();
            config.responseType = 'blob';

            //Lanzamos petición get
            let response = await axios.post('/api/' + url, data, config);

            const resultUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = resultUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

        } catch (exception) {
            console.error("Error al realizar petición POST al endpoint " + url);
            console.error(exception);
            return null;
        }
    }

    /**
     * Realiza una petición POST a la API
     * @param {String} url Url a llamar
     * @param {Object} data Cuerpo de la petición
     * @returns {Object} Resultado de la petición
     */
    async apiPost(url, data) {
        try {
            //Construímos URL
            let finalUrl = '/api/' + url;

            //Comprobamos si está en cache
            let fromHistory = this._getFromHistory('POST', finalUrl, data);
            if (fromHistory !== null)
                return fromHistory;

            //Lanzamos petición post
            let response = await axios.post(finalUrl, data, this._getRequestConfig());

            if (response.data.success === true) {
                //Si estamos utilizando cache, almacenamos el resultado
                this._addToHistory('POST', finalUrl, response.data, data);

                //Éxito
                return response.data;
            } else {
                //Error
                console.error("Error al realizar petición POST al endpoint " + url);
                return null;
            }
        } catch (exception) {
            console.error("Error al realizar petición POST al endpoint " + url);
            return null;
        }
    }

    /**
     * Realiza una petición DELETE a la API
     * @param {String} url Url a llamar
     * @returns {Object} Resultado de la petición
     */
    async apiDelete(url) {
        try {
            //Construímos URL
            let finalUrl = '/api/' + url;

            //Comprobamos si está en cache
            let fromHistory = this._getFromHistory('DELETE', finalUrl);
            if (fromHistory !== null)
                return fromHistory;

            //Lanzamos petición delete
            let response = await axios.delete(finalUrl, this._getRequestConfig());
            if (response.data.success === true) {

                //Si estamos utilizando cache, almacenamos el resultado
                this._addToHistory('DELETE', finalUrl, response.data);

                //Éxito
                return response.data;
            } else {
                //Error
                console.error("Error al realizar petición DELETE al endpoint " + url);
                return null;
            }
        } catch (exception) {
            console.error("Error al realizar petición DELETE al endpoint " + url);
            return null;
        }
    }

    /**
     * Retorna la configuración con la que realizar las llamadas http
     * @returns {Object} Configuración de la petición http
     */
    _getRequestConfig() {
        let config = {};

        //Si estamos logados
        const authToken = localStorage.getItem("authToken");
        if (authToken) {
            //Añadimos configuración para cabeceras
            config.headers = {};
            //Definimos cabeceras de autenticación
            config.headers['Authorization'] = `Bearer ${authToken}`;
        }
        return config;
    }

}
export default BasicApi;