import BasicApi from './BasicApi';

/**
 * Llamadas para gestionar clientes
 */
class ClientApi extends BasicApi {

    async create(name) {
        let response = await super.apiPost("clients/create", {name});
        return response;
    }

    async getAll(){
        let response = await super.apiGet("clients");
        return response;
    }

    async createLicense(license){
        let response = await super.apiPost("clients/license", license);
        return response;
    }

    async delete(id){
        let response = await super.apiDelete("clients/"+id);
        return response;
    }

    async getLogs(){
        let response = await super.apiGet("logs");
        return response;
    }

}

export default ClientApi;