import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CreateClient from './CreateClient';
import CreateLicense from './CreateLicense';
import LicenseInfo from './LicenseInfo';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from './../AppStructure/Table';
import {
  withRouter
} from 'react-router-dom'
//Apis
import ClientApi from '../../api/ClientApi';

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 650,
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }));


function Clients(props){
  const classes = useStyles();
  const [selectedClient, setSelectedClient] = useState({});
  const [showCreateLicense, setCreateLicense] = useState(false);
  const [showCreateDialog, setCreateDialog] = useState(false);
  const [showLicenseResult, setLicenseResult] = useState(false);
  const [licenseResult, setLicenseData] = useState("");
  const [loading, setLoading] = useState(true);
  const [customLoading, setCustomLoading] = useState(false);
  const [clients, setClients] = useState([]);

  const handleCreate = async (name) => {
    let clientApi = new ClientApi();
    await clientApi.create(name);
    setCreateDialog(false);
    setLoading(true);
  };

  const handleCreateLicense = async (license) => {
    setCreateLicense(false);
    setCustomLoading(true);
    let clientApi = new ClientApi();
    let licenseResult = await clientApi.createLicense(license);
    setLicenseData(licenseResult.data.result);
    setLicenseResult(true);
    setCustomLoading(false);
  };

  const onClick = (client) => {
    //props.history.push('/clients/id-'+client._id);
    setSelectedClient(client);
    setCreateLicense(true);
  };

  const onDelete = async (client) => {
    let clientApi = new ClientApi();
    await clientApi.delete(client._id);
    setLoading(true);
  };

  useEffect(() =>{(async function(){
    let clientApi = new ClientApi();
    let clients = await clientApi.getAll();
    setClients(clients.data);
    setLoading(false);
  })()}, [loading, setClients, setLoading]);

  if (loading === true || customLoading === true){
    return <LinearProgress variant="query" />;
  }
  
  return (
    <React.Fragment>
      <Table onDelete={onDelete} onClick={onClick} columns={[{label: 'Name', value: 'name'}, {label: 'Id', value: '_id'}]} rows={clients} />
      <Fab onClick={() => setCreateDialog(true)} color="secondary" className={classes.fab}>
        <AddIcon />
      </Fab>
      <CreateClient showDialog={showCreateDialog} handleCreate={handleCreate} handleClose={() => setCreateDialog(false)} />
      <LicenseInfo license={licenseResult} showDialog={showLicenseResult} handleClose={() => setLicenseResult(false)} />
      <CreateLicense client={selectedClient} showDialog={showCreateLicense} handleCreate={handleCreateLicense} handleClose={() => setCreateLicense(false)} />
    </React.Fragment>
);
}

export default withRouter(Clients);