import React, { useEffect, useState } from 'react';
 
import LoginApi from '../../api/LoginApi';
import { useDispatch } from 'react-redux';
import { login} from '../../app/slices/sessionSlice';
import LoginForm from './LoginForm';

//#region Comprobación de sesión
async function checkSession(loginState, dispatchLogin){
  
    const loginApi = new LoginApi();
    const urlHasToken = hasToken();

    //Si ya tenemos token guardado en el navegador
    const currentSession = localStorage.getItem('authToken');
    if (currentSession !== null && currentSession !== ""){
        const currentSessionCheck = await loginApi.verifyStoredSession();
        //Tenemos sesión
        if (currentSessionCheck !== null){
            const loginResult = login(currentSessionCheck.data);
            dispatchLogin(loginResult);
            return;
        }
        
        //La sesión era inválida :(
        localStorage.setItem('authToken', '');
    }

    //No tenemos token, si hemos pulsado el botón redireccionamos
    if (!urlHasToken && loginState === true){
        redirectToExternalLogin();
        return;
    }
    
    //Si no tenemos token pero no hemos pulsado el botón, no hacemos nada
    if (!urlHasToken && !loginState){
        return;
    }

    //Tenemos token, con lo que vamos a validarlo: Pedimos a la API que compruebe el token
    const urlToken = document.location.href.split('?token=')[1];
    const response = await loginApi.get(urlToken);

    //Si la sesión es null, es que no era válido
    if (response.data.session === null){
        redirectToExternalLogin();
        return;
    }

    //No era null: Tenemos sesión válida
    localStorage.setItem('authToken', response.data.token);
    const loginResult = login(response.data.session);
    dispatchLogin(loginResult);
  
}

function getRedirectUrl(){
  return `https://services.vsn-tv.com/workspace/modules/remote_session/start_remote_session.php?callback=${document.location.origin}`;
}

function hasToken(){
  return document.location.href.includes("?token=");
}

function redirectToExternalLogin(){
    const redirectUrl = getRedirectUrl();
    //Hacemos login
    document.location.href = redirectUrl;
}
//#endregion

//Página de login
function Login(props) {

    const dispatchLogin = useDispatch();
    const [loginState, setLoginState] = useState(false);

    //Después de renderizar
    useEffect(() => {
        checkSession(loginState, dispatchLogin);
    }, [loginState, dispatchLogin]);

    return (
        <LoginForm appName={props.appName} onLogin={()=>{setLoginState(true)}} />
    );
} 

export default Login;
