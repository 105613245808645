import React from 'react';
import {
    Route,
    Redirect
} from "react-router-dom";

import About from './features/About/About';
import Clients from './features/Clients/Clients';
import Logs from './features/Logs/Logs';
function Routes(){


    return (<React.Fragment>
        <Route exact path="/"><Redirect to='/clients' /></Route>
        <Route exact path="/clients"><Clients/></Route>
        <Route exact path="/log"><Logs /></Route>
        <Route exact path="/about"><About /></Route>
    </React.Fragment>);

}


export default Routes;