import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) => ({
  pointer: {
    cursor: 'pointer'
  },
}));

function BuiltTable(props){
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            {props.columns.map((item)=>{
              return <TableCell key={item.value}>{item.label}</TableCell>;
            })}
            {props.onDelete && <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => {

            return (<TableRow key={row._id} >
              {props.columns.map((item)=>{
                return <TableCell key={row[item.value]} onClick={() => {props.onClick && props.onClick(row)}} className={classes.pointer}>{row[item.value]}</TableCell>;
              })}

              {props.onDelete && <TableCell className={classes.pointer}><Icon onClick={() => {props.onDelete(row)}}>close</Icon></TableCell>}
            </TableRow>);
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

};


export default BuiltTable;